import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueAnalytics from 'vue-ua'
import VueSilentbox from 'vue-silentbox'
import Axios from 'axios'
import QueryString from 'querystring'
//import jquery from 'jquery'

Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'NucleoDev', // '<app_name>',
  // [Required] The version of your app.
  appVersion: '<app_version>',
  // [Required] Your Google Analytics tracking ID.
  trackingId: 'UA-151736675-1', // '<your_tracking_id>',
  // If you're using vue-router, pass the router instance here.
  vueRouter: router,

  // Global Dimensions and Metrics can optionally be specified.
  globalDimensions: [
    { dimension: 1, value: 'FirstDimension' },
    { dimension: 2, value: 'SecondDimension' }
    // Because websites are only 2D, obviously. WebGL? What's that?
  ],

  globalMetrics: [
    { metric: 1, value: 'MyMetricValue' },
    { metric: 2, value: 'AnotherMetricValue' }
  ]
})

// Install BootstrapVue
Vue.use(BootstrapVue);

//Plugin de Imagenes
Vue.use(VueSilentbox);

// Axios Configuration
Axios.defaults.headers.common.Accept = 'application/json'
Vue.prototype.$axios = Axios;

Vue.use(QueryString);

//Vue.use(jquery);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
