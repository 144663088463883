<template>
    <div>
        <div style="position: relative; background: #F5F5F5">
          <img class="verdeDesk" src="@/assets/images/Figura01.png" alt="" style="width: 100%;min-height: 100px;">
          <div class="container-fluid" style="padding: 0;">
            <div style="display: flex; justify-content: center">
              <h2 class="container pt-lg-4 pt-md-4 pt-sm-3 pt-3" style="position: absolute; color: white; top: 0; text-align: center; margin-top: 10px">
                Aviso de Privacidad
              </h2>
            </div>
          </div>

        </div>
        <div class="container-fluid p-5" style="text-align: left;">
        <p class="text-bold m-0">IDENTIDAD Y DOMICILIO DEL RESPONSABLE.</p>
        <br>
        <span class="text-bold">SISTEMA INJECTHOR DE MEXICO, S. DE R.L. DE C.V.</span>, (en adelante Sistema Injecthor de México) con domicilio en Privada del Camichin #1974, Colonia Villas de la Loma, Zapopan, Jalisco, México, Código Postal 45130, los datos personales y personales sensibles, de nuestros consumidores y posibles consumidores (en adelante “datos personales”), son tratados de forma estrictamente privada y confidencial, por lo que la obtención, tratamiento, transferencia y ejercicio de los derechos derivados de dichos datos personales, se hace mediante un uso adecuado, legítimo y lícito, salvaguardando permanentemente los principios de licitud, consentimiento, calidad, información, proporcionalidad, responsabilidad, lealtad y finalidad, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y disposiciones secundarias.</p><br>
        <span class="text-bold">OBTENCIÓN DE DATOS PERSONALES Y FINALIDADES DE SU TRATAMIENTO.</span><br><br>
        <p class="m-0">En <span class="text-bold">Sistema Injecthor de México</span>, estamos comprometidos con salvaguardar la privacidad de sus datos personales y mantener una relación estrecha y activa con nuestros consumidores y posibles consumidores por eso además de éste Aviso, aplicamos nuestra Política de Privacidad, te invitamos a conocerla.</p>
        <br><p class="m-0">A continuación, señalamos expresa y limitativamente los datos que podremos recabar de usted como consumidor y posible consumidor: </p>
        <p class="m-0">Datos Personales de identificación y contacto:</p><br>
        <ul>
            <li>Nombre, apellido paterno y materno, género, domicilio, domicilio donde serán aplicados nuestros servicios,código postal, número telefónico, número celular y correo electrónico.</li>
        </ul>
        <br>
        <p class="m-0">Con respecto de los datos personales antes mencionados, se garantiza un tratamiento, ya sea directa o indirectamente a través de <span class="text-bold">Sistema Injecthor de México</span>, sus subsidiarias, afiliadas o empresas relacionadas, y sus terceros proveedores de servicios con quienes tiene una relación jurídica; obran y tratan datos personales por cuenta de <span class="text-bold">Sistema Injecthor de México</span>, así como, en su caso, por las autoridades competentes, para las finalidades que dieron origen y son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica con sus consumidores, que son las siguientes: </p>

        <ul>
            <li>Para dar cumplimiento a la relación jurídica establecida con usted derivado de la adquisición de alguno de los productos o servicios de las Marcas al proveerle información sobre el mismo.</li>
            <li>Para atender quejas o recomendaciones respecto del uso y aplicación de los productos o servicios de las Marcas. </li>
        </ul>

        <p class="m-0">De manera adicional, <span class="text-bold">Sistema Injecthor de México</span> podrá tratar sus datos personales para las siguientes finalidades distintas o secundarias, que no son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica con sus consumidores, pero que nos permiten brindarle una mejor atención:</p>

        <ul>
            <li>Mercadotecnia, publicidad, prospección comercial, evaluación de nuestros productos y servicios relacionados con la Marca, incluyendo el envío de muestras gratis y/o artículos promocionales. </li>
            <li>Para fines comerciales y de prospección comercial incluso respecto de cualquier otra marca comercializada y distribuida por <span class="text-bold">Sistema Injecthor de México</span>. </li>
        </ul>

        <p class="m-0">La negativa otorgada para el tratamiento de sus datos personales para las finalidades distintas o secundarias señaladas previamente, no podrá ser un motivo para que le neguemos los productos o servicios derivados de la adquisición de los productos o servicios que adquiere o contrata con nosotros. </p>

        <br><p class="m-0">Derivado de lo anterior, <span class="text-bold">Sistema Injecthor de México</span>, le informa que, en caso de que usted no desee que sus datos personales sean tratados para las finalidades distintas o secundarias que se precisan en el presente documento, le solicitamos nos envíe un correo electrónico a la dirección: <span style="color: #266DD3; text-decoration: underline;">proteccióndatos@injecthormexico.com.mx</span>, a efecto de que nuestro “Departamento de proteccion de datos” documente dicha situación mediante su inscripción en nuestro listado de exclusión denominado “Contactos que no desean envío de publicidad”. </p>

        <br><p class="m-0">Es importante mencionar que, en caso de que <span class="text-bold">Sistema Injecthor de México</span>, pretendiera utilizar sus datos personales sensibles para las finalidades secundarias descritas con anterioridad, previo a su tratamiento, solicitará su consentimiento expreso y por escrito de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás disposiciones secundarias obligatorias, de lo contrario no hará uso de los mismos para estos fines. </p>

        <br><p class="m-0">Los datos personales que usted proporcione a <span class="text-bold">Sistema Injecthor de México</span> serán tratados exclusivamente para las finalidades descritas, se les tratará de forma confidencial y exclusivamente en la medida en que éstos sean estrictamente necesarios para cumplir la finalidad para la cual hubieren sido recabados. </p>

        <br><p class="m-0">Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este aviso de privacidad, hemos implementado procedimientos físicos, electrónicos y administrativos, utilizando tecnologías avanzadas que limitan el uso o divulgación de sus datos, permitiéndonos tratarlos debidamente y prohibiendo extraer de las oficinas de <span class="text-bold">Sistema Injecthor de México</span>, de forma no autorizada, cualquier tipo de información que contenga datos personales. </p>


        <br><p class="text-bold m-0">TRANSFERENCIAS DE DATOS PERSONALES A TERCEROS. </p> 

        <p class="m-0">Por medio de este aviso de privacidad, <span class="text-bold">Sistema Injecthor de México</span> le informa que podrá transferir sus datos personales de identificación y contacto (no sensibles) a terceros nacionales o extranjeros; tales como a sus subsidiarias, afiliadas o empresas relacionadas y proveedores de servicios con los que mantenga una relación jurídica, para las siguientes finalidades: </p>

        <ul><li>Mercadotecnia, publicidad y prospección comercial.</li></ul>

        <br>
        <p class="m-0">Derivado de lo anterior <span class="text-bold">Sistema Injecthor de México</span> le informa que, en caso de que usted no desee que sus datos personales sean transferidos a los terceros que se mencionan en el presente apartado y para las finalidades aquí descritas, le solicitamos atentamente nos envíe un correo electrónico a la dirección <span style="color: #266DD3; text-decoration: underline;">proteccióndatos@injecthormexico.com.mx</span>, a efecto de que nuestro “Oficial de Protección de Datos Personales” documente esta situación y tome las medidas necesarias a fin de que sus datos no sean utilizados para los fines antes descritos, de lo contrario entenderemos que ha manifestado su pleno consentimiento para su transferencia en los términos precisados en el aviso de privacidad que nos ocupa. </p>

        <br><p class="m-0">Asimismo, le informamos que en caso de que <span class="text-bold">Sistema Injecthor de México</span>, pretenda transferir sus datos personales sensibles (en caso de haberlos proporcionado) a los terceros aquí precisados para las finalidades anteriormente descritas en el presente apartado, previo a su transferencia solicitará su consentimiento expreso y por escrito, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás disposiciones secundarias obligatorias, de lo contrario no hará uso de los mismos para estos fines. </p>

        <br><p class="m-0">En este sentido, <span class="text-bold">Sistema Injecthor de México</span> le informa que, de conformidad con lo dispuesto por el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá transferir sus datos personales, sin su consentimiento, en los casos que a continuación se especifican: </p>
        <br>
        <ul>
            <li>Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte. </li>
            <li>Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios. </li>
            <li>Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo de <span class="text-bold">Sistema Injecthor de México</span> que opere bajo los mismos procesos y políticas internas. </li>
            <li>Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular de datos, por <span class="text-bold">Sistema Injecthor de México</span> y un tercero. </li>
            <li>Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia. </li>
            <li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial. </li>
            <li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre <span class="text-bold">Sistema Injecthor de México</span> y el titular de datos. </li>
        </ul>
        <br>
        <p class="text-bold">MECANISMOS Y PROCEDIMIENTOS PARA LA REVOCACIÓN DEL CONSENTIMIENTO AL TRATAMIENTO DE DATOS PERSONALES Y/O EJERCICIO DE DERECHOS ARCO.  </p>

        <p class="m-0" >En todo momento usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir su uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros. </p>

        <br><p class="m-0">Para revocar su consentimiento deberá acudir a nuestras oficinas y solicitar el “Formato de solicitud de revocación del consentimiento” el cual también hemos puesto a su disposición en nuestro portal de internet <span style="color: #266DD3; text-decoration: underline;">www.injecthormexico.com.mx</span>, acompañando a dicha solicitud los documentos que acrediten su identidad (identificación oficial) o, en su caso, de quien legalmente le represente. Asimismo, usted podrá solicitar dicho formato a nuestro “Encargado de Protección de Datos Personales”, a través del correo electrónico <span style="color: #266DD3; text-decoration: underline;">proteccióndatos@injecthormexico.com.mx</span> o al teléfono (33) 13802333. </p>

        <br><p class="m-0">Asimismo, usted tiene derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales (derechos ARCO). En este sentido, como titular de sus datos personales tiene el derecho de conocer qué datos personales mantiene Sistema Injecthor de México, para qué los utilizamos y las condiciones del uso que les damos (Acceso), así como a rectificarlos en caso de ser inexactos, desactualizados o incompletos (Rectificación), instruirnos a cancelarlos cuando estos resulten excesivos o innecesarios para las finalidades descritas que justificaron su obtención, considere que los mismos no están siendo utilizados conforme a los principios, deberes y obligaciones previstas en la normativa aplicable (Cancelación), o bien, oponerse al uso de sus datos personales para fines específicos (Oposición), en los casos en que la Ley Federal de Protección de Datos Personales en Posesión de los Particulares así lo permita. </p>

        <br><p class="m-0">Para ejercer los derechos ARCO conforme lo mencionado en el párrafo anterior, podrá acudir a nuestras oficinas y solicitar el “Formato de solicitud de derechos ARCO”, el cual también hemos puesto a su disposición en nuestro portal de internet <span style="color: #266DD3; text-decoration: underline;">www.injecthormexico.com.mx</span>, acompañando a dicha solicitud los documentos que acrediten su identidad (identificación oficial) o, en su caso, de quien legalmente le represente. Asimismo, usted podrá solicitar dicho formato a nuestro “Encargado de Protección de Datos Personales”, a través del correo electrónico <span style="color: #266DD3; text-decoration: underline;">proteccióndatos@injecthormexico.com.mx</span> </p>

        <br><p class="m-0"><span class="text-bold">Sistema Injecthor de México</span> atenderá su petición en los tiempos estipulados en las leyes aplicables y le informará de las consecuencias que deban producirse con motivo del ejercicio de su derecho. </p>

        <br><p class="m-0">Para conocer puntualmente el procedimiento establecido por <span class="text-bold">Sistema Injecthor de México</span> para la revocación del consentimiento respecto del tratamiento de sus datos personales y/o respecto del ejercicio de los derechos ARCO, hemos puesto a su disposición en nuestro portal de internet <span style="color: #266DD3; text-decoration: underline;">www.injecthormexico.com.mx</span> un documento que los detalla, o bien, usted podrá acudir personalmente a nuestra oficinas y contactar al “Encargado de Protección de Datos Personales”, así como contactarlo a través del correo electrónico <span style="color: #266DD3; text-decoration: underline;">proteccióndatos@injecthormexico.com.mx</span>, donde con gusto le informaremos sobre los detalles del procedimiento y requisitos para el ejercicio de sus derechos. </p>

        <br><p class="m-0">Si considera que no ha recibido una respuesta de <span class="text-bold">Sistema Injecthor de México</span> o bien la respuesta que se le dio no es satisfactoria, usted tiene el derecho de acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) a presentar una solicitud de protección de datos dentro de los quince días siguientes a la fecha en que venza el plazo para que <span class="text-bold">Sistema Injecthor de México</span> de respuesta a su solicitud de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, así como del procedimiento que hemos instaurado para ello, o bien, de la respuesta que hubiésemos dado a la misma. </p>

        <br>
        <p class="text-bold m-0">MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES  </p>
        <br>
        <p><span class="text-bold">Sistema Injecthor de México</span> le informa que, con el objeto de que usted pueda limitar el uso o divulgación de sus datos personales, ponemos a su disposición la dirección de correo electrónico <span style="color: #266DD3; text-decoration: underline;">proteccióndatos@injecthormexico.com.mx</span>, a efecto de ser inscrito en nuestro listado de exclusión denominado “Contactos que no desean envío de publicidad”, a fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios o de prospección comercial, así como para evaluación de nuestros productos y servicios por nuestra parte. </p>
        <br>
        <p class="text-bold m-0">USO DE COOKIES, WEB BEACONS U OTRAS TECNOLOGÍAS  </p>

        <br><p class="m-0"><span class="text-bold">Sistema Injecthor de México</span> le informa que, podrá utilizar mecanismos en medios remotos o locales de comunicación electrónica, óptica u otra tecnología; tales como cookies y web beacons, que permitan recabar diversos datos de manera automática y simultánea al tiempo que usted como consumidor o posible consumidor hace contacto con los mismos. Si quieres tener más información, por favor, consulta nuestra Política de Privacidad, donde podrás revisar cómo desactivarlos y en su caso, qué cookies estamos usando para recabar tus datos personales. </p>

        <br><p class="m-0">Los cookies son aquellos archivos de datos que se almacenan en el disco duro del equipo de cómputo o del dispositivo de comunicaciones electrónicas de un usuario al navegar en un sitio de Internet específico, el cual permite intercambiar información de estado entre dicho sitio y el navegador del usuario. La información de estado puede revelar en algunos casos medios de identificación de sesión, autenticación o preferencias del usuario, así como cualquier otro dato almacenado por el navegador respecto del sitio de Internet. </p>

        <br><p class="m-0">Se entiende por web beacons a aquella imagen visible u oculta insertada dentro de un sitio web o correo electrónico, que se utiliza para monitorear el comportamiento del usuario en estos medios. A través de éstos se puede obtener información como la dirección IP de origen, navegador utilizado, sistema operativo, momento en que se accedió a la página, y en el caso del correo electrónico, la asociación de los datos anteriores con el destinatario. </p>

        <br><p class="m-0">En concreto, Sistema Injecthor de México, utilizará cookies y web beacons al enviar correos electrónicos con información relacionada con la Marca y el resto de las marcas que distribuye y comercializa, ya que al momento en que usted reciba y, en su caso, lea alguno de nuestros correos, por medio de estas tecnologías podremos recabar los siguientes datos: </p>

        <br><p class="m-0">i) Conocer si abrió o no el correo electrónico que le enviamos y; 
        <br>ii) Conocer si accedió o no a la(s) liga(s) o URL(s) contenidas en el correo. </p>

        <br><p class="m-0">Asimismo, le informamos que los datos que se obtienen a través de estas tecnologías se utilizarán exclusivamente para fines estadísticos y de revisión de resultados, sin que exista la posibilidad de transferirlos a terceros. </p>

        <br><p class="m-0">La mayoría de los exploradores acepta las cookies automáticamente. Aun así, es posible configurar cualquier explorador para evitar que acepte cookies e impedir de este modo que su equipo o dispositivo las almacene. Algunos exploradores cuentan con un modo especial que garantiza la eliminación de las cookies tras una visita. Consulte el manual de su explorador si desea conocer los pasos exactos que permiten activar el modo correspondiente. Puede eliminar las cookies que se encuentren almacenadas en su equipo o dispositivo en cualquier momento. </p>

        <br><p class="m-0">En este sentido, en apoyo a usted y para ejemplificarlo establecemos la forma en que se pueden deshabilitar las cookies en el navegador Internet Explorer y que son las siguientes: 
        <br>i) Abrir en su computadora el navegador Internet Explorer. 
        <br>ii) Al ingresar hacer click en el botón Herramientas y después en Opciones de Internet
        <br>iii) Posteriormente hacer click en la ficha Privacidad
        <br>A continuación, en Configuración, mueva el control deslizante totalmente hacia arriba para bloquear todas las cookies o totalmente hacia abajo para permitir todas las cookies y, a continuación, haga clic en Aceptar. 
        <br>En ningún otro caso, <span class="text-bold">Sistema Injecthor de México</span>, utilizará este tipo de tecnologías. En caso contrario, lo hará de su conocimiento previamente. </p>
        <br>
        <p class="text-bold m-0">MODIFICACIONES Y CAMBIOS AL AVISO DE PRIVACIDAD</p>
        <br>
        <p class="m-0">Este aviso de privacidad podrá ser modificado o actualizado de tiempo en tiempo según sea requerido, por <span class="text-bold">Sistema Injecthor de México</span>. Dichas modificaciones serán informadas a través de una publicación en nuestro portal de internet posteriormente a su modificación. 
        <br>
        <p class="m-0">Uso de Redes Sociales “Plug-ins” Plug-ins ("plug-ins") de las redes sociales facebook.com y Twitter pueden ser añadidos en nuestras páginas web. Los servicios asociados son proporcionados por las compañías de Facebook Inc. y Twitter Inc., respectivamente ("Proveedores").
        <br>Facebook es operado por Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, EE.UU. ("Facebook"). Para una visión general del Facebook plug-ins y su apariencia, vaya a:
        https://developers.facebook.com/docs/plugins
        <br>Twitter es operado por Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, EE.UU.. Para una visión general de los opciones de Twitter y su aspecto, vaya a: 
        https://dev.twitter.com/web/tweet-button 
        <br>Para aumentar la protección de los datos cuando visita nuestras páginas web, estos plug-ins se implementan como "2-click buttons". Esta forma de integración asegura que, al acceder a una página dentro de nuestra página web que contenga dichos plug-ins, no están conectado automáticamente a los servidores de los proveedores. Sólo si activa los plug-ins, emitiendo así un permiso para la transmisión de datos, el navegador creará un vínculo directo a los servidores de los proveedores. El contenido de los diversos plug-ins se transmite por el proveedor en cuestión directamente en el navegador y luego aparece en la pantalla.
        <br>El plug-in le dice al proveedor cuáles de nuestras páginas web ha accedido. Si, mientras está viendo nuestro sitio web, se inicia la sesión en su cuenta de usuario de Facebook o Twitter, el proveedor es capaz de compaginar su interés, es decir, la información que usted está accediendo con su cuenta de usuario. Al hacer uso de cualquiera de las funciones de plug-in (por ejemplo, al hacer clic en el botón "Me gusta", dejando un comentario), esta información también será transmitida por el navegador directamente al proveedor para registrarlo.
        <br>Para más información sobre esto y uso de datos por Facebook o Twitter, así como sobre los derechos y posibilidades disponibles para proteger su privacidad en estas circunstancias, se puede encontrar en los avisos de protección de datos / privacidad de los proveedores:
        <br>Protección de datos / aviso de privacidad emitido por Facebook: <span style="color: #266DD3; text-decoration: underline;">http://www.facebook.com/policy.php </span>
        <br>Protección de datos / aviso de privacidad emitido por Twitter: <span style="color: #266DD3; text-decoration: underline;">https://twitter.com/privacy</span></p>
        <br><p class="m-0">Para evitar el permitir que Facebook o Twitter vinculen su visita de nuestro sitio web a su cuenta de usuario con estos proveedores, tendrá que desconectarse de la cuenta de usuario correspondiente antes de acceder a nuestras páginas web.
        <br><br>
        <p class="m-0"><span class="text-bold">Fecha de Publicación:</span> 01 de Noviembre de 2020 </p>
        <p class="m-0 pb-5"><span class="text-bold">Fecha de última Actualización:</span> Diciembre de 2020</p>
        </div>
    </div>
</template>

<script>
  import ImagenVerde from "@/components/Inicio/ImagenVerde";
  export default {
    name: "Aviso",
    components: {ImagenVerde},
    data(){
      return{
        parrafoVerde: 'Aviso de Privacidad'
      }
    }
  }
</script>

<style scoped>

.text-bold {
    font-weight: bold;
}

</style>