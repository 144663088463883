<template>
  <div style="background: white; margin-top: 45px; padding-top: 40px; padding-bottom: 20px;">
    <div class="carouselMovil" style="margin-left: 30px; margin-bottom: 30px; padding-top: 50px; padding-bottom: 50px;">
      <h1 class="letrasMovilesHeader" style="border-left: 10px solid #A0AF84; text-align: left; padding-left: 20px;">
        Nuestros Clientes Opinan…
      </h1>
      <b-carousel
        id="carouselPrincipal"
        v-model="slide"
        :interval="4000"
        controls
        background="white"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
    >

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Excelentes resultados en el tratamiento de plagas como <span style="font-style: italic;">rhynchophorus palmarum</span> y <span style="font-style: italic;">raoiella indica</span>, así como para hongos. con muy buen costo-beneficio.
                </p>
              <div class="d-flex">
                <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Tranquilino Gálvez </p><p class="pl-2" style="color:#A0AF84;"> - Los Cabos b.c.s.</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Sistema con muy alta efectividad en el control de problemas de plagas en palmeras en quintana roo. Rápido, económico y efectivo.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Felipe Mena </p><p class="pl-2" style="color:#A0AF84;"> - Cancún, Quintana Roo. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Resultados muy buenos en el tratamiento de insectos descortezadores de cedro y pino a un precio muy accesible.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Mario Hernández </p><p class="pl-2" style="color:#A0AF84;"> -  Morelia, Michoacán. Ciudad tres marías.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Hemos trabajado con esta empresa con el sistema de inyección con muy buenos resultados para el picudo del cardón <span style="font-style: italic;">(scyphophorus acupunctatus)</span> las trampas y atrayentes muy buenos y de excelente calidad.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Perla Cervantes </p><p class="pl-2" style="color:#A0AF84;"> - Baja California Sur.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Muy efectivo el tratamiento que le realizaron a mis arboles afectados por el descortezador del fresno.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Ignacio Gómez </p><p class="pl-2" style="color:#A0AF84;"> - Zapopan, Jalisco.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Tratamiento muy eficaz y económico para el problema de la mosca blanca en los ficus. altamente recomendable.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Cesar Chávez </p><p class="pl-2" style="color:#A0AF84;"> -  Guadalajara</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Sistema muy eficaz para el control de barrenador del tronco del nogal. Lo estamos utilizando mucho en la zona de la laguna con excelentes resultados.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">José R. </p><p class="pl-2" style="color:#A0AF84;"> - Torreón, Coahuila. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Empresa muy recomendable con excelentes resultados a bajo costo para problemas de descortezadores y barrenadores.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Cesar Chávez </p><p class="pl-2" style="color:#A0AF84;"> - Víctor García</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-blank class="estiloCarousel">
        <div style="display: flex; justify-content: center; position: absolute; top: 0; width: 100%; height: 100%">
          <div class="media px-4 px-lg-2 flex-column flex-md-row align-items-center tamanioItemCarousel estilosGaleria" style="width: 70%;">
            <div style="display: flex">
              <div class="margenMovil" style="height: 50%; margin-left: 70px">
                <img src="@/assets/images/ComillasCarousel.png" alt="">
              </div>
              <div class="media-body d-lg-block" style="text-align: left;margin-left: 20px;">
                <p class="letrasMoviles" style="text-shadow: none; color: black">
                  Los tratamientos recomendados para el tratamiento en aguacate son extraordinarios tanto el de suelo como el de la inyección. solucione la tristeza del aguacate y problemas de insectos rápido y barato en mi huerta.
                </p>
                <div class="d-flex">
                  <p style="margin: 0; font-weight: bold; text-shadow: none; color: black">Iván Álvarez </p><p class="pl-2" style="color:#A0AF84;"> - Morelia, Michoacán.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

    </b-carousel>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Carousel",
    data(){
      return{
        left: '',
        right: '',
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    },
    mounted() {
      this.left = require('@/assets/images/LeftCarouselTestimonio.png');
      this.right = require('@/assets/images/RightCarouselTestimonio.png');
    },
    computed: {
      style(){
        console.log(this.left);
        console.log(this.right);
        return;
      }
    }
  }
</script>

<style scoped>

  .carousel-control-next-icon{
    background-image: url("/RightCarouselTestimonio.png");
  }

  .carousel-control-prev-icon{
    background-image: url("/LeftCarouselTestimonio.png");
  }

  .estiloCarousel {
    background: white;
    height: 200px;
  }

  @media(max-width: 769px){

    .estilosGaleria{
      display: grid;
      justify-content: center;
    }

    .estiloCarousel{
      height: 200px !important;
    }

    .carouselMovil{
      margin: 0 !important;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .margenMovil{
      margin-left: 0 !important;
    }

    .letrasMoviles{
      font-size: 12px;
    }

    .letrasMovilesHeader{
      font-size: 20px;
    }

    .tamanioItemCarousel{
      padding: 0 !important;
    }
  }

  @media (max-width: 767px){
    .carouselMovil{
      margin: 0 !important;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .margenMovil{
      margin-left: 0 !important;
    }

    .estiloCarousel{
      height: 400px !important;
    }

    .letrasMoviles{
      font-size: 12px;
    }

    .letrasMovilesHeader{
      font-size: 20px;
    }

    .tamanioItemCarousel{
      padding: 0 !important;
    }
  }
</style>

<style>
  .carouselPrincipal{
    position: absolute !important;
  }
</style>