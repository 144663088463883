<template>
  <div>
    <b-button v-b-modal.modal-1 class="btn btn-dark">Enviar</b-button>

    <b-modal id="modal-1">
      <p id="pContact" class="my-4" style="font-weight: bold">
        Hemos recibido tu mensaje, ¡muchas gracias! <br>
        Pronto nos pondrémos en contacto contigo.
      </p>
      <template v-slot:modal-footer="{ ok, cancel, hide }" style="text-align: center">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="ok()" class="btn btn-success">
          De acuerdo
        </b-button>

      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "ModalContac"
  }
</script>

<style>

  #modal-1___BV_modal_footer_, #modal-1___BV_modal_header_{
    border: none !important;
  }

  #modal-1___BV_modal_footer_{
    justify-content: center;
    display: none;
  }

  #modal-1___BV_modal_content_{
    border-radius: 15px;
    border: none;
  }

  #modal-1___BV_modal_header_{
    display: none;
  }

  .form-control:focus{
    box-shadow: none;
  }

  #pContact{
    text-align: center;
  }

</style>

<style scope>

  .btn-dark{
    border-radius: 30px;
    background: #1b1e21;
    color: white;
    width: 200px;
    height: 50px;
  }

  .btn-success{
    background: #A0AF84 !important;
    border-radius: 30px !important;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 1px solid #A0AF84 !important;
  }

  .btn-success:hover{
    background: #218838 !important;
    border: none !important;
  }
</style>