<template>
  <div>
    <ImagenPrincipal :dataImage="imagenInicio" :dataTitulo="tituloInicio" :dataParrafo="parrafoInicio"></ImagenPrincipal>
    <ImagenVerde :texto="parrafoVerde"></ImagenVerde>
    <div class="margenLetras" style="margin-left: 30px; margin-bottom: 70px">
      <h1 style="border-left: 10px solid #A0AF84; text-align: left; padding-left: 20px; margin-bottom: 35px">
        Ventajas
      </h1>
      <div class="listaPuntos" style="display: flex; text-align: left; justify-content: center;">
        <ul>
          <li>Acción directa al sistema vascular o transportador.</li>
          <li>Velocidad en la asimilación de los productos inyectados.</li>
          <li>Seguridad en la dosificación de los productos.</li>
          <li>Dosis mínimas de aplicación.</li>
          <li>Cero ( 0 ) desperdicio de la dosis, aprovechándola en un 100%.</li>
          <li>Cero ( 0 ), impacto ambiental en todos sus aspectos y niveles.</li>
          <li>Seguridad del personal aplicador en un 100 %.</li>
          <li>Resultados inmediatos y directos de los productos.</li>
        </ul>
        <ul>
          <li>Recuperación inmediata y paulatina del árbol tratado.</li>
          <li>Prolongada acción residual. 1 a 2 años.</li>
          <li>Velocidad de trabajo en las aplicaciones.</li>
          <li>No requiere de grandes cantidades de agua para su aplicación.</li>
          <li>Control total y absoluto de plagas, carencias y enfermedades endémicas.</li>
          <li>Bajo costo de aplicación.</li>
          <li>Aplicable en toda clase de árboles.</li>
        </ul>
      </div>
    </div>

    <div style="position: relative; display: flex; justify-content: center; align-items: center;">

      <img src="@/assets/images/Figura02.png" alt="" style="width: 100%; min-height: 355px;position: absolute" class="imgVerdeMovil">

      <div class="container movilImgTubo">
        <div class="row" style="align-items: center;">
          <div class="col-md-4 col-sm-12">
            <img src="@/assets/images/Tubo.png" alt="" style="margin-right: 50px" class="imgMovil">
          </div>
          <div class="col-md-8 col-sm-12">
            <div class="sizeLetras" style="text-align: left; color: white;">
              <p>Su efectividad es 100 % superior a otros sistemas de aspersión o inyección de suelos o estratos.</p>
              <p>
                Se oferta el Sistema Injecthor de bajo costo en distintos puntos de la republica, consistente en el comodato
                de maquinaria y capacitación gratuita a entidades públicas y privadas, con la finalidad de salvar árboles.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="margenLetras" style="padding-left: 30px; margin-bottom: 30px; background: white; padding-top: 90px; padding-bottom: 50px;">
      <h1 style="border-left: 10px solid #A0AF84; text-align: left; padding-left: 20px;">
        Características
      </h1>
      <div class="container">
        <div class="row tamanioBloques" style="margin-top: 20px; height: 230px;">
          <div class="col-md-4 col-sm-12" style="display: flex; justify-content: center; align-items: center; margin-bottom: 25px;">
            <div class="margenBloques" style="width: 300px; height: 170px">
              <div style="display: flex; justify-content: center; align-items: center">
                <img src="@/assets/images/Rapidez.svg" alt="" style="margin-bottom: 20px">
              </div>
              <h5 class="text-center" style="margin-bottom: 10px"><b>Rapidez</b></h5>
              <p class="text-center">
                Cualquier inyección se absorbe entre 30 minutos y 48 horas, dependiendo del estado del árbol y su variedad.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-12" style="display: flex; justify-content: center; align-items: center; margin-bottom: 25px;">
            <div class="margenBloques" style="width: 300px; height: 170px">
              <div style="display: flex; justify-content: center; align-items: center">
                <img src="@/assets/images/Persistencia.svg" alt="" style="margin-bottom: 20px">
              </div>
              <h5 class="text-center" style="margin-bottom: 10px"><b>Persistencia</b></h5>
              <p class="text-center">
                Una aplicación puede ser suficiente para corregir la carencia o el problema en la fitopatología del árbol,
                manteniéndose durante casi dos años.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-12" style="display: flex; justify-content: center; align-items: center; margin-bottom: 25px;">
            <div class="margenBloques" style="width: 300px; height: 170px">
              <div style="display: flex; justify-content: center; align-items: center">
                <img src="@/assets/images/Limpieza.svg" alt="" style="margin-bottom: 20px">
              </div>
              <h5 class="text-center" style="margin-bottom: 10px"><b>Limpieza</b></h5>
              <p class="text-center">
                Evita la contaminación, especialmente en tratamiento de plagas, donde habitualmente se utiliza el método
                de aspersiones.
              </p>
            </div>
          </div>
        </div>
        <div class="row tamanioBloques" style="margin-top: 20px; height: 230px;">
          <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center; align-items: center; margin-bottom: 25px;">
            <div class="margenBloques" style="width: 300px; height: 170px">
              <div style="display: flex; justify-content: center; align-items: center">
                <img src="@/assets/images/Eficacia.svg" alt="" style="margin-bottom: 20px">
              </div>
              <h5 class="text-center" style="margin-bottom: 10px"><b>Eficacia</b></h5>
              <p class="text-center">
                Al inyectar el principio activo directamente en la savia del árbol, aseguramos una distribución uniforme
                y una reacción positiva e inmediata.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center; align-items: center; margin-bottom: 25px;">
            <div class="margenBloques" style="width: 300px; height: 170px">
              <div style="display: flex; justify-content: center; align-items: center">
                <img src="@/assets/images/Costo.svg" alt="" style="margin-bottom: 20px">
              </div>
              <h5 class="text-center" style="margin-bottom: 10px"><b>Bajo Costo</b></h5>
              <p class="text-center">
                Los costos son inferiores a los tratamientos convencionales.
                Puede aplicarse en parques, jardines, o en el terreno agrícola y forestal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="margenLetras" style="margin-left: 30px;">
      <h1 style="border-left: 10px solid #A0AF84; text-align: left; padding-left: 20px; margin-bottom: 35px">
        Equipo Utilizado
      </h1>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-sm-12" style="text-align: left; align-items: center; display: flex;">
            <div>
              <ul>
                <li>Maquina de inyección</li>
                <li>Válvulas Injecthor</li>
                <li>Producto especialmente formulado en base a diagnóstico</li>
                <li>Taladro</li>
                <li>Broca especial de madera</li>
                <li>Desinfectante</li>
              </ul>
              <p>Fabricamos injecthores de todas las medidas requeridas según el tipo de árbol.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12" style="padding: 0">
            <img src="@/assets/images/Equipo.png" alt="" style="width: 100%">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImagenPrincipal from "@/components/Inicio/ImagenPrincipal";
  import ImagenVerde from "@/components/Inicio/ImagenVerde";
  export default {
    name: "SIA",
    components: {ImagenVerde, ImagenPrincipal},
    data(){
      return{
        imagenInicio: 'InjecthorBanner02.png',
        tituloInicio: 'Injecthor Árboles',
        parrafoInicio: 'Sistema de inyección para tratamientos preventivos y curativos en árboles.',
        parrafoVerde: 'Proporciona a la planta los nutrientes para la recuperación total. Ahorra tiempo, \n' +
                      'producto, agua, sin impacto ambiental, ni contaminación de suelo o agua y es 100% más efectivo.'
      }
    }
  }
</script>

<style scoped>

  @media(max-width: 769px) {
    .imgVerdeMovil{
      min-height: 250px !important;
    }

    .listaPuntos{
      display: block !important;
    }

    .margenLetras{
      margin-left: 0 !important;
      padding-left: 0 !important;
    }

    .tamanioBloques{
      height: auto !important;
    }

    .margenBloques {
      height: auto !important;
    }

    .imgMovil{
      margin-right: 0 !important;
      width: 50%;
    }

    .sizeLetras{
      font-size: 12px;
    }
  }

  @media(max-width: 767px){
    .imgVerdeMovil {
      min-height: 440px !important;
    }

    .movilImgTubo{
      margin-top: 35px;
    }

    .listaPuntos{
      display: block !important;
    }

    .margenLetras{
      margin-left: 0 !important;
      padding-left: 0 !important;
    }

    .tamanioBloques{
      height: auto !important;
    }

    .margenBloques {
      height: auto !important;
    }

    .imgMovil{
      margin-right: 0 !important;
      width: 50%;
    }

    .sizeLetras{
      font-size: 12px;
    }
  }
</style>