<template>
  <div id="app">
    <div style="height: 87px">
      <div style="position: absolute">
        <div id="nav" style="position: fixed; width: 100%; z-index: 99; opacity: 95%; box-shadow: 0 1px 5px 1px black;">
          <b-navbar toggleable="lg" type="dark" variant="info" style="background: #1E1E1F !important; border-top: 2px solid #A0AF84; position: relative">

            <b-navbar-brand href="/">
              <img alt="Logo de Injecthor" src="../src/assets/images/logo.png" style="width: 200px;">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav style="justify-content: end; justify-content: flex-end !important;">
              <b-navbar-nav class="porcentaje" style="width: 80%;justify-content: space-between;">

                <b-nav-item >

                  <router-link to="/" class="inicio">
                    <div style="font-weight: normal; height: 48px; display: flex; align-items: center;">
                      Inicio
                    </div>
                  </router-link>
                </b-nav-item>
                <b-nav-item>
                  <router-link to="/injecthorArboles">
                    <div class="disponible" style="font-weight: normal;">
                      Sistema <br>
                      Injecthor Árboles
                    </div>
                    <div class="disponibleMovil" style="font-weight: normal;">
                      Sistema Injecthor Árboles
                    </div>
                  </router-link>
                </b-nav-item>
                <b-nav-item>
                  <router-link to="/biotecnologia">
                    <div class="disponible" style="font-weight: normal;">
                      Biotecnología <br>
                      Agroindustrial
                    </div>
                    <div class="disponibleMovil" style="font-weight: normal;">
                      Biotecnología Agroindustrial
                    </div>
                  </router-link>
                </b-nav-item>
                <b-nav-item>
                  <router-link to="/trampas">
                    <div class="disponible" style="font-weight: normal;">
                      Trampas <br>
                      y Atrayentes
                    </div>
                    <div class="disponibleMovil" style="font-weight: normal;">
                      Trampas y Atrayentes
                    </div>
                  </router-link>
                </b-nav-item>
                <b-nav-item><!--href="/galeria" class="letrasMovilesMenu"-->

                  <router-link to="/galeria" class="galeria letrasMovilesMenu">
                    <div style="font-weight: normal; height: 48px; display: flex; align-items: center;">
                      Galería
                    </div>
                  </router-link>

                </b-nav-item>
                <b-nav-item href="/#Contacto" class="btnContacto letrasMovilesMenu">
                  <div style="font-weight: normal;">
                    Contacto
                  </div>
                </b-nav-item>

              </b-navbar-nav>
            </b-collapse>

          </b-navbar>
        </div>
      </div>
      <div style="position: fixed; z-index: 1; width: 100%;">
        <a href="https://api.whatsapp.com/send?phone=523332015883" target="_blank">
          <img class="imgWhats" src="@/assets/images/Whats.png" alt="">
        </a>
      </div>
    </div>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: white;
}

#nav a.router-link-exact-active div {
  border-right: 2px solid #A0AF84;
  border-left: 2px solid #A0AF84;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: bold !important;
}

.nav-item {
  display: flex;
  align-items: center;
}

* {
  font-family: Futura;
}

p{
  margin: 0;
}

.disponibleMovil{
  display: none;
}

.imgWhats{
  position: absolute;
  z-index: 1;
  width: 70px;
  right: 10px;
  bottom: -300px;
}

.imgWhats:hover{
  transform: scale(1.1, 1.1);
}

#silentbox-overlay__description {
  padding-top: 0 !important;
}

#silentbox-overlay__container {
  margin: 0 !important;
}

@media(max-width: 768px){
  #nav-collapse{
    border-radius: 0px 0px 0px 20px;
    position: absolute;
    background: rgb(30, 30, 31) none repeat scroll 0% 0%;
    top: 85px;
    z-index: 1;
    right: 0px;
    width: 75%;
    padding-left: 20px;
  }

  .disponibleMovil{
    display: block;
    font-size: 13px;
  }

  .letrasMovilesMenu{
    font-size: 13px;
  }

  .disponible{
    display: none;
  }

  .porcentaje {
    width: 100% !important;
  }

  .imgWhats{
    position: absolute;
    z-index: 1;
    width: 50px;
    right: 10px;
    bottom: -300px;
  }
}

</style>
<script>
import Footer from "@/components/Footer";
export default {
  components: {Footer}
}
</script>