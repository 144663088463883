import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categorias: [
      {
        titulo: 'Barrenadores ambrosiales',
        mensaje: 'Xyleborus spp',
      },
      {
        titulo: 'Insectos Defoliadores',
        mensaje: 'Zadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.',
      },
      {
        titulo: 'Picudo del agave o del cardon',
        mensaje: 'Scyphophorus acupunctatus',
      },
      {
        titulo: 'Descortezador de Fresno',
        mensaje: 'Hylesinus aztecus',
      },
      {
        titulo: 'Descortezador de Cedro',
        mensaje: 'Phloeosinus sp.',
      },
      {
        titulo: 'Descortezador Menor',
        mensaje: 'Phloeosinus sp.',
      },
      {
        titulo: 'Mosca blanca del Ficus',
        mensaje: 'Singhiella simplex',
      },

      {
        titulo: 'Picudo Negro de la Palmera',
        mensaje: '(Rhynchophorus palmarum) Hongos y Bactería',
      },

      {
        titulo: 'Equipo',
        mensaje: 'Medición de PH, Conductividad Eléctrica, Tomografías Dimensionales, Escaneo de Insectos, Penetrometros de Compactación de Suelo, Análisis de Suelo, etc.',
      },

      {
        titulo: 'Equipo',
        mensaje: 'Medición de PH, Conductividad Eléctrica, Tomografías Dimensionales, Escaneo de Insectos, Penetrometros de Compactación de Suelo, Análisis de Suelo, etc.',
      }
    ],
    imagenes: [
      { idCategoria: 0, src: '00.png' },
      { idCategoria: 0, src: '01.png' },
      { idCategoria: 0, src: '02.png' },
      { idCategoria: 0, src: '03.png' },

      { idCategoria: 1, src: '04.png' },
      { idCategoria: 1, src: '05.png' },
      { idCategoria: 1, src: '06.png' },
      { idCategoria: 1, src: '07.png' },

      { idCategoria: 2, src: '08.png' },
      { idCategoria: 2, src: '09.png' },
      { idCategoria: 2, src: '010.png' },
      { idCategoria: 2, src: '011.png' },

      { idCategoria: 3, src: '012.png' },
      { idCategoria: 3, src: '013.png' },
      { idCategoria: 3, src: '014.png' },
      { idCategoria: 3, src: '015.png' },

      { idCategoria: 4, src: '016.png' },
      { idCategoria: 4, src: '017.png' },
      { idCategoria: 4, src: '018.png' },
      { idCategoria: 4, src: '019.png' },

      { idCategoria: 5, src: '020.png' },
      { idCategoria: 5, src: '021.png' },
      { idCategoria: 5, src: '022.png' },
      { idCategoria: 5, src: '023.png' },

      { idCategoria: 6, src: '024.png' },
      { idCategoria: 6, src: '025.png' },
      { idCategoria: 6, src: '026.png' },
      { idCategoria: 6, src: '027.png' },

      { idCategoria: 7, src: '028.png' },
      { idCategoria: 7, src: '029.png' },
      { idCategoria: 7, src: '030.png' },
      { idCategoria: 7, src: '031.png' },

      { idCategoria: 8, src: '032.png' },
      { idCategoria: 8, src: '033.png' },
      { idCategoria: 8, src: '034.png' },
      { idCategoria: 8, src: '035.png' },

      { idCategoria: 9, src: '036.png' },
      { idCategoria: 9, src: '037.png' },
      { idCategoria: 9, src: '038.png' },
      { idCategoria: 9, src: '039.png' },

      { idCategoria: 10, src: '040.png' },
      { idCategoria: 10, src: '041.png' },
      { idCategoria: 10, src: '042.png' },
      { idCategoria: 10, src: '043.png' }

    ],
    megaArreglo: [
      {
        titulo: 'Barrenadores ambrosiales',
        mensaje: 'Xyleborus spp',
        src: '00Barrenadores ambrosialesXyleborus spp.png'
      },
      {
        titulo: 'Barrenadores ambrosiales',
        mensaje: 'Xyleborus spp',
        src: '01Barrenadores ambrosialesXyleborus spp.png'
      },
      {
        titulo: 'Barrenadores ambrosiales',
        mensaje: 'Xyleborus spp',
        src: '02Barrenadores ambrosialesXyleborus spp.png'
      },
      {
        titulo: 'Barrenadores ambrosiales',
        mensaje: 'Xyleborus spp',
        src: '03Barrenadores ambrosialesXyleborus spp.png'
      },
      {
        titulo: 'Insectos Defoliadores',
        mensaje: 'Zadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.',
        src: '04Insectos DefoliadoresZadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.png'
      },
      {
        titulo: 'Insectos Defoliadores',
        mensaje: 'Zadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.',
        src: '05Insectos DefoliadoresZadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.png'
      },
      {
        titulo: 'Insectos Defoliadores',
        mensaje: 'Zadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.',
        src: '06Insectos DefoliadoresZadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.png'
      },
      {
        titulo: 'Insectos Defoliadores',
        mensaje: 'Zadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.',
        src: '07Insectos DefoliadoresZadiprion spp, Neodiprion spp, Monoctenus spp y Malcosoma spp.png'
      },
      {
        titulo: 'Picudo del agave o del cardon',
        mensaje: 'Scyphophorus acupunctatus',
        src: '08Picudo del agave o del cardonScyphophorus acupunctatus.png'
      },
      {
        titulo: 'Picudo del agave o del cardon',
        mensaje: 'Scyphophorus acupunctatus',
        src: '09Picudo del agave o del cardonScyphophorus acupunctatus.png'
      },
      {
        titulo: 'Picudo del agave o del cardon',
        mensaje: 'Scyphophorus acupunctatus',
        src: '010Picudo del agave o del cardonScyphophorus acupunctatus.png'
      },
      {
        titulo: 'Picudo del agave o del cardon',
        mensaje: 'Scyphophorus acupunctatus',
        src: '011Picudo del agave o del cardonScyphophorus acupunctatus.png'
      },
      {
        titulo: 'Descortezador de Fresno',
        mensaje: 'Hylesinus aztecus',
        src: '012Descortezador de FresnoHylesinus aztecus.png'
      },
      {
        titulo: 'Descortezador de Fresno',
        mensaje: 'Hylesinus aztecus',
        src: '013Descortezador de FresnoHylesinus aztecus.png'
      },
      {
        titulo: 'Descortezador de Fresno',
        mensaje: 'Hylesinus aztecus',
        src: '014Descortezador de FresnoHylesinus aztecus.png'
      },
      {
        titulo: 'Descortezador de Fresno',
        mensaje: 'Hylesinus aztecus',
        src: '015Descortezador de FresnoHylesinus aztecus.png'
      },
      {
        titulo: 'Descortezador de Cedro',
        mensaje: 'Phloeosinus sp.',
        src: '016Descortezador de CedroPhloeosinus sp.png'
      },
      {
        titulo: 'Descortezador de Cedro',
        mensaje: 'Phloeosinus sp.',
        src: '017Descortezador de CedroPhloeosinus sp.png'
      },
      {
        titulo: 'Descortezador de Cedro',
        mensaje: 'Phloeosinus sp.',
        src: '018Descortezador de CedroPhloeosinus sp.png'
      },
      {
        titulo: 'Descortezador de Cedro',
        mensaje: 'Phloeosinus sp.',
        src: '019Descortezador de CedroPhloeosinus sp.png'
      },

      {
        titulo: 'Descortezador de Pino',
        mensaje: 'Dendroctonus sp.',
        src: '020Descorteador de PinoDendroctonus sp.png'
      },
      {
        titulo: 'Descortezador de Pino',
        mensaje: 'Dendroctonus sp.',
        src: '021Descorteador de PinoDendroctonus sp.png'
      },
      {
        titulo: 'Descortezador de Pino',
        mensaje: 'Dendroctonus sp.',
        src: '022Descorteador de PinoDendroctonus sp.png'
      },
      {
        titulo: 'Descortezador de Pino',
        mensaje: 'Dendroctonus sp.',
        src: '023Descorteador de PinoDendroctonus sp.png'
      },

      {
        titulo: 'Descortezador Menor',
        mensaje: 'Ips spp.',
        src: '024Descortezador menor.png'
      },

      {
        titulo: 'Descortezador Menor',
        mensaje: 'Ips spp.',
        src: '025Descortezador menor.png'
      },

      {
        titulo: 'Descortezador Menor',
        mensaje: 'Ips spp.',
        src: '026Descortezador menor.png'
      },

      {
        titulo: 'Descortezador Menor',
        mensaje: 'Ips spp.',
        src: '027Descortezado menor.png'
      },

      {
        titulo: 'Mosca blanca del Ficus',
        mensaje: 'Singhiella simplex',
        src: '028Mosca blanca del FicusSinghiella simplex.png'
      },
      {
        titulo: 'Mosca blanca del Ficus',
        mensaje: 'Singhiella simplex',
        src: '029Mosca blanca del FicusSinghiella simplex.png'
      },
      {
        titulo: 'Mosca blanca del Ficus',
        mensaje: 'Singhiella simplex',
        src: '030Mosca blanca del FicusSinghiella simplex.png'
      },
      {
        titulo: 'Mosca blanca del Ficus',
        mensaje: 'Singhiella simplex',
        src: '031Mosca blanca del FicusSinghiella simplex.png'
      },
      {
        titulo: 'Picudo Negro de la Palmera',
        mensaje: '(Rhynchophorus palmarum) Hongos y Bacterías',
        src: '032picudo negro de la palmera.png'
      },
      {
        titulo: 'Picudo Negro de la Palmera',
        mensaje: '(Rhynchophorus palmarum) Hongos y Bacterías',
        src: '033picudo negro de la palmera.png'
      },
      {
        titulo: 'Picudo Negro de la Palmera',
        mensaje: '(Rhynchophorus palmarum) Hongos y Bacterías',
        src: '034picudo negro de la palmera.png'
      },
      {
        titulo: 'Picudo Negro de la Palmera',
        mensaje: '(Rhynchophorus palmarum) Hongos y Bacterías',
        src: '035picudo negro de la palmera.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Equipo térmico para escaneo de insectos',
        src: '036equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Penetrómetros para medición de compactación de suelo',
        src: '037equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Medidores de PH, Conductividad Eléctrica y Temperatura',
        src: '038equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Tests rápidos de análisis de suelo',
        src: '039equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Calliper para realización de tomografías.',
        src: '040equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Calliper para realización de tomografías.',
        src: '041equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Tomógrafos sónicos y de resistividad eléctrica para diagnóstico en arbolado.',
        src: '042equipo.png'
      },
      {
        titulo: 'Equipo',
        mensaje: 'Tomógrafos sónicos y de resistividad eléctrica para diagnóstico en arbolado.',
        src: '043equipo.png'
      },
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
