<template>
  <div class="card mb-3" style="max-width: 540px;">
    <div class="row no-gutters">
      <div class="col-md-4">
        <img  class="card-img" alt="...">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title"></h5>
          <p class="card-text">
          </p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Cards",
    data(){
      return {
        imagen: [{nombre: ''}],
        paquete: {
          titulo: '',
          tamanio: 0,
          info: [
              {data: ''}
          ]
        }
      }
    },
    props: ['imagenProp', 'tituloProp', 'tamanioProp', 'dataProp[{data}]'],
    computed: {
      llenado(){
        for (let i=0; i<this.tamanioProp; i++){
          this.info[i].data = this.dataProp[i].data;
        }
        return this.info;
      }
    },
    mounted() {
      for (let i=0; i<this.tamanioProp; i++){
        this.imagen[i].nombre = this.imagenProp[i].nombre;
        this.imagen[i].nombre = require('@/assets/images/' + this.imagenProp[i].nombre);
      }
    }
  }
</script>

<style scoped>

</style>