<template>
  <div>
    <b-form id="formContacto" @submit="onSubmit" @reset="onReset">

      <b-form-group
          id="grupo01">
        <b-form-input
            id="input-1"
            name="form[name]"
            v-model="form.name"
            type="text"
            required
            placeholder="Escribe tu nombre"
            class="inputStyle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="grupo02">
        <b-form-input
            id="input-2"
            name="form[email]"
            v-model="form.email"
            type="email"
            required
            placeholder="Escribe tu e-Mail"
            class="inputStyle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="grupo03">
        <b-form-textarea
            id="input-3"
            name="form[description]"
            v-model="form.description"
            placeholder="¿Cómo podemos ayudarte?"
            required
            rows="3"
            max-rows="6"
            class="inputStyle"
            no-resize
        ></b-form-textarea>
      </b-form-group>


      <div style="display: flex; justify-content: center">

        <b-button id="show-btn" class="btn btn-dark" type="submit" variant="primary">Enviar</b-button>

      </div>

      <div>
        <b-modal ref="modal-1" hide-footer hide-header>
          <p id="pContact" class="my-4" style="font-weight: bold">
            Hemos recibido tu mensaje, ¡muchas gracias! <br>
            Pronto nos pondrémos en contacto contigo.
          </p>
          <template style="text-align: center !important;">

            <div style="text-align: center;">
              <b-button id="hide-btn" size="sm" variant="success" v-on:click="onReset" class="btn btn-success">
                De acuerdo
              </b-button>
            </div>


          </template>
        </b-modal>
      </div>

    </b-form>
  </div>
</template>

<script>
  import QueryString from "querystring";
  import axios from 'axios';

  export default {
    name: "FormContacto",
    data() {
      return {
        php: '',
        form: {
          email: '',
          name: '',
          description: ''
        },
        show: true
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        this.$axios.post(
                "https://injecthormexico.com.mx/calls/enviar.php",
                QueryString.stringify(this.form)
            ).then(res => {
              this.sent = true;
              console.log(res.config);
            }).then(function (response) {
              console.log(response);
            }).catch(function (error) {
              console.log(error);
            });
        this.$refs['modal-1'].show();
      },
      onReset(evt) {
        evt.preventDefault()

        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.description = ''

        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        });
        this.$refs['modal-1'].hide();
      }
    }
  }
</script>

<style scoped>

  .inputStyle{
    background: none;
    border: none;
    margin-bottom: 10px;
    border-bottom: 1px solid #A0AF84;
    border-radius: 0;
    overflow-y: hidden !important;
  }

  .btn-dark{
    border-radius: 30px;
    background: #1b1e21;
    color: white;
    width: 200px;
    height: 50px;
  }

  .btn-success{
    background: #A0AF84 !important;
    border-radius: 30px !important;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 1px solid #A0AF84 !important;
  }

  .btn-success:hover{
    background: #218838 !important;
    border: none !important;
  }

</style>
