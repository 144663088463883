<template>
  <div>

    <img :id="'modal-'+CargarPosicion" v-on:click="$bvModal.show('modal-'+CargarPosicion)" :src="srcGaleria" style="width: 100%; cursor: pointer">
    <p style="font-family: Futura; font-size: 16px; color: black; margin-top: 5px; margin-bottom: 5px">{{ CargaTitulo }}</p>
    <p style="font-family: Arial; font-style: italic; font-size: 14px; margin-bottom: 20px">{{ CargaMensaje }}</p>

    <b-modal :id="'modal-'+CargarPosicion" title="BootstrapVue" centered hide-footer hide-header content-class="contenido" body-class="cuerpo">
      <button type="button" aria-label="Close" class="close" v-on:click="$bvModal.hide('modal-'+CargarPosicion)">×</button>
      <GaleriaDinamica :indexImg="CargarPosicion" :title="CargaTitulo" :desc="CargaMensaje" :src="this.src"></GaleriaDinamica>
    </b-modal>

  </div>
</template>

<script>
  import GaleriaDinamica from "@/components/GaleriaDinamica";
  import ModalGaleria from "@/components/modalGaleria";
  export default {
    name: "ImagenesGaleria",
    components: {ModalGaleria, GaleriaDinamica},
    props: ['titulo', 'mensaje', 'src', 'indice'],
    data(){
      return{
        srcGaleria: '',
        posicion: 0,
      }
    },
    computed: {
      CargaTitulo(){
        return this.titulo;
      },
      CargaMensaje(){
        return this.mensaje;
      },
      CargarPosicion(){
        return this.indice;
      }
    },
    mounted() {
      this.srcGaleria = require('@/assets/images/Galeria/' + this.src);
    }
  }
</script>

<style scoped>

.verdeDesk{
  display: block;
}

.verdeMovil{
  display: none;
}

@media (max-width: 768px){

  .verdeDesk{
    display: none;
  }

  .verdeMovil{
    display: block;
  }

}
</style>