<template>
  <div style="position: relative">
    <img :src="nameImage" alt="" style="width: 100%; min-height: 150px;">
    <div style="position: absolute;bottom: 0;text-align: left;color: white;padding: 30px; text-shadow: 2px 2px black;">
      <h1>{{Titulo}}</h1>
      <h4>{{Parrafo}}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagenPrincipal",
  data(){
    return{
      nameImage: '',
      titulo: '',
      parrafo: ''
    }
  },
  props:['dataImage', 'dataTitulo', 'dataParrafo'],
  computed: {
    Titulo(){
      this.titulo = this.dataTitulo;
      return this.titulo;
    },
    Parrafo(){
      this.parrafo = this.dataParrafo;
      return this.parrafo;
    }
  },
  mounted(){
    this.nameImage = require('@/assets/images/' + this.dataImage);
  }
}
</script>

<style scoped>
  @media (max-width: 768px){
    h1{
      font-size: 18px;
    }
    h4{
      font-size: 16px;
    }
  }
</style>