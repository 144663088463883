<template>
  <div style="position: relative; background: #F5F5F5">
    <img class="verdeDesk" src="@/assets/images/Figura01.png" alt="" style="width: 100%;min-height: 150px;">
    <div class="container-fluid" style="padding: 0;">
      <img class="verdeMovil" src="@/assets/images/verdeTopMovil.svg" alt="" style="width: 100%;">
      <div style="display: flex; justify-content: center">
        <p class="container" style="position: absolute; color: white; top: 0; font-size: 24px; text-align: center; margin-top: 10px">
          {{insertarTexto}}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "ImagenVerde",
    data(){
      return{
        parrafo: ''
      }
    },
    props: ['texto'],
    computed: {
      insertarTexto(){
        this.parrafo = this.texto;
        return this.parrafo;
      }
    }
  }
</script>

<style scoped>

  .verdeDesk{
    display: block;
  }

  .verdeMovil{
    display: none;
  }

  @media (max-width: 768px){
    p{
      font-size: 12px !important;
    }

    .verdeDesk{
      display: none;
    }

    .verdeMovil{
      display: block;
    }

  }

  @media (max-width: 325px){
    p{
      font-size: 10px !important;
    }
  }

</style>