<template>
  <div>
    <ImagenPrincipal :dataImage="imagenInicio" :dataTitulo="tituloInicio" :dataParrafo="parrafoInicio"></ImagenPrincipal>
    <ImagenVerde :texto="parrafoVerde"></ImagenVerde>

    <div class="container-fluid" style="margin-bottom: 50px; margin-top: 20px;">
      <div class="row Cards">
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/BioCard01.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title text-left">Bacterias y Protectores <br>Fungicidas</h5>
                  <p class="card-text" style="font-size: 13px;">
                    <ul style="text-align: left">
                      <li>Mezcla de <span style="font-style:italic;">Bacillus subtillis</span>, <span style="font-style:italic;">Trichoderma harzianum</span> y activadores orgánicos (Trichobas).</li>
                      <li>Controla y elimina patógenos de raíz, como <span style="font-style:italic;">Rhizotocnia solani</span>, <span style="font-style:italic;">Fusarium</span>, entre otros.</li>
                      <li>Controla y elimina enfermedades como <span style="font-style:italic;">Xanthomonas</span> y <span style="font-style:italic;">Pseudomonas</span>.</li>
                      <li>Estimula el crecimiento y desarrollo radicular mejorando la nutrición y absorción de agua.</li>
                    </ul>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn='btnBio01' :modal="nameModalBio01" :idModal="idModalBio01"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/BioCard02.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title text-left">
                    Insecticida microbial de amplio <br>
                    espectro (contacto e ingestión)
                  </h5>
                  <p class="card-text" style="font-size: 13px;">
                  <ul style="text-align: left">
                    <li>Para el control de diversos insectos entre ellos defoliadores.</li>
                    <li>Mezcla de <span style="font-style:italic;">Bacillus Thuringiensis</span> + <span style="font-style:italic;">Beauveria Bassiana</span> + <span style="font-style:italic;">Metarhizium Anisoplaea</span> + <span style="font-style:italic;">Paecilomyces Lilacinus</span>.</li>
                  </ul>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn='btnBio02' :modal="nameModalBio02" :idModal="idModalBio02"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/BioCard03.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8" style="max-height: 320px;">
                <div class="card-body">
                  <h5 class="card-title text-left">
                    Nutrición Vegetal y Minerales <br>
                    Agrícolas
                  </h5>
                  <p class="card-text" style="font-size: 13px;">
                  <ul style="text-align: left">
                    <li>Extracto de alga marina <span style="font-style:italic;">Ascophyllum Nodosum</span> y <span style="font-style:italic;">Yuca Schidigera</span>.</li>
                    <li>Enriquecido con aminoacidos libres, con variedad de hormonas de crecimiento naturales
                      (Citocininas, Auxinas, Giberelinas), así como oligoelementos (Ca, S, Bo, Mo, Cu, Fe, Zn, Mn, Co
                      y Si) Aminoácidos, Carbohidratos, Enzimas, Vitaminas y Saponinas Esteroidales.</li>
                  </ul>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn='btnBio03' :modal="nameModalBio03" :idModal="idModalBio03"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/BioCard04.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8" style="max-height: 320px;">
                <div class="card-body">
                  <h5 class="card-title text-left">Minerales Agropecuarios</h5>
                  <p class="card-text" style="font-size: 13px; text-align: left">
                   <b style="margin-left: 40px; margin-bottom: 5px">Tierra de Diatomeas:</b>
                  <ul style="text-align: left">
                    <li>
                      Insecticida Ecológico Protector de Granos almacenados, producto mineral 100% natural para el
                      control de insectos.
                    </li>
                    <li>Acción física al deshidratar insectos, fertilizante y mejorador de suelos.</li>
                  </ul>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn='btnBio04' :modal="nameModalBio04" :idModal="idModalBio04"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="movilCards">
        <div class="row">

          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; margin-bottom: 20px; height: 580px;">
              <img src="@/assets/images/BioCard01M.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">
                  Bacterias y Protectores Fungicidas
                </h5>
                <p class="card-text" style="font-size: 13px;">
                  <ul style="text-align: left">
                    <li>Mezcla de <span style="font-style:italic;">Bacillus subtillis</span>, <span style="font-style:italic;">Trichoderma harzianum</span> y activadores orgánicos (Trichobas).</li>
                    <li>Controla y elimina patógenos de raíz, como <span style="font-style:italic;">Rhizotocnia solani</span>, <span style="font-style:italic;">Fusarium</span>, entre otros.</li>
                    <li>Controla y elimina enfermedades como <span style="font-style:italic;">Xanthomonas</span> y <span style="font-style:italic;">Pseudomonas</span>.</li>
                    <li>Estimula el crecimiento y desarrollo radicular mejorando la nutrición y absorción de agua.</li>
                  </ul>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0;">
                  <ModalForm :textBtn='btnBio01' :modal="nameModalBio01+'M'" :idModal="idModalBio01+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; margin-bottom: 20px; height: 490px;">
              <img src="@/assets/images/movil_heli.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">
                  Insecticida microbial de amplio espectro (contacto e ingestión)
                </h5>
                <p class="card-text" style="font-size: 13px;">
                <ul style="text-align: left">
                  <li>Para el control de diversos insectos entre ellos defoliadores.</li>
                  <li>
                    Mezcla de <span style="font-style:italic;">Bacillus Thuringiensis</span> + <span style="font-style:italic;">Beauveria Bassiana</span> + <span style="font-style:italic;">Metarhizium Anisoplaea</span> +
                    <span style="font-style:italic;">Paecilomyces Lilacinus</span>.</li>
                </ul>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0;">
                  <ModalForm :textBtn='btnBio02' :modal="nameModalBio02+'M'" :idModal="idModalBio02+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; margin-bottom: 20px; height: 600px;">
              <img src="@/assets/images/BioCard03M.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">
                  Nutrición Vegetal y Minerales Agrícolas
                </h5>
                <p class="card-text" style="font-size: 13px;">
                <ul style="text-align: left">
                  <li>
                    Extracto de alga marina <span style="font-style:italic;">Ascophyllum Nodosum</span> y <span style="font-style:italic;">Yuca Schidigera</span>.
                  </li>
                  <li>
                    Enriquecido con aminoacidos libres, con variedad de hormonas de crecimiento naturales
                    (Citocininas, Auxinas, Giberelinas), así como oligoelementos (Ca, S, Bo, Mo, Cu, Fe, Zn, Mn, Co
                    y Si) Aminoácidos, Carbohidratos, Enzimas, Vitaminas y Saponinas Esteroidales.
                  </li>
                </ul>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0;">
                  <ModalForm :textBtn='btnBio03' :modal="nameModalBio03+'M'" :idModal="idModalBio03+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; margin-bottom: 20px; height: 495px;">
              <img src="@/assets/images/BioCard04M.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">Minerales Agropecuarios</h5>
                <p class="card-text" style="font-size: 13px; text-align: left">
                  <b style="margin-left: 40px; margin-bottom: 5px">Tierra de Diatomeas:</b>
                <ul style="text-align: left">
                  <li>
                    Insecticida Ecológico Protector de Granos almacenados, producto mineral 100% natural para el
                    control de insectos.
                  </li>
                  <li>Acción física al deshidratar insectos, fertilizante y mejorador de suelos.</li>
                </ul>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0;">
                  <ModalForm :textBtn='btnBio04' :modal="nameModalBio04+'M'" :idModal="idModalBio04+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImagenPrincipal from "@/components/Inicio/ImagenPrincipal";
  import ImagenVerde from "@/components/Inicio/ImagenVerde";
  import Cards from "@/components/Cards";
  import ModalForm from "@/components/ModalForm";
  export default {
    name: "Bio",
    components: {ModalForm, ImagenPrincipal, ImagenVerde, Cards},
    data(){
      return{
        imagenInicio: 'InjecthorBanner03.png',
        tituloInicio: 'Biotecnología Agroindustrial',
        parrafoInicio: 'Insumos para agricultura, productos orgánicos biológicos y ecológicos.',
        parrafoVerde: 'Contamos con productos especialmente formulados para el tratamiento de plagas y enfermedades \n' +
                      'agrícolas, forestales y urbanas.',
        imagen: [
          {nombre: 'BioCard01.png'},
          {nombre: 'BioCard02.png'},
          {nombre: 'BioCard03.png'},
          {nombre: 'BioCard04.png'}
        ],
        tamanio: 4,

        btnBio01: 'Solicitar Protector',
        nameModalBio01: 'modalBio01',
        idModalBio01: 'modalBio01',

        btnBio02: 'Solicitar Insecticida',
        nameModalBio02: 'modalBio02',
        idModalBio02: 'modalBio02',

        btnBio03: 'Solicitar Nutrición...',
        nameModalBio03: 'modalBio03',
        idModalBio03: 'modalBio03',

        btnBio04: 'Solicitar Mineral',
        nameModalBio04: 'modalBio04',
        idModalBio04: 'modalBio04'
      }
    }
  }
</script>
<style scoped>

.btn-success{
  background: #1E1E1F !important;
  color: white;
  border-radius: 30px;
  border: none;
  width: 200px;
  height: 50px;
}

.btn-success:hover{
  background: #A0AF84 !important;
}

.card.mb-3:hover {
  box-shadow: 0 0px 4px 1px black;
}

.Cards{
  display: flex;
}

.movilCards{
  display: none;
}

@media (max-width: 768px) {
  .Cards{
    display: none;
  }

  .movilCards{
    display: block;
  }
}

</style>