import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SIA from '../views/SIA.vue'
import Bio from '../views/Bio.vue'
import Trampas from '../views/Trampas.vue'
import Galeria from '../views/Galeria.vue'
import Contacto from '../views/Contacto.vue'
import Aviso from '../views/AvisoPrivacidad.vue'


Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '/injecthorArboles',
      name: 'SIA',
      component: SIA
    },
    {
      path: '/biotecnologia',
      name: 'Bio',
      component: Bio
    },
    {
      path: '/trampas',
      name: 'Trampas',
      component: Trampas
    },
    {
      path: '/galeria',
      name: 'Galeria',
      component: Galeria
    },
    {
      path: '/aviso-privacidad',
      name: 'Aviso',
      component: Aviso
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
