<template>
  <div>
    <ImagenPrincipal :dataImage="imagenInicio" :dataTitulo="tituloInicio" :dataParrafo="parrafoInicio"></ImagenPrincipal>
    <ImagenVerde :texto="parrafoVerde"></ImagenVerde>
    <div class="container-fluid" style="margin-bottom: 50px; margin-top: 20px;">
      <div class="row Cards">
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/TrapCard01.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title text-left">
                    Trampas Multiembudos
                  </h5>
                  <p class="card-text" style="font-size: 13px;">
                  <ul style="text-align: left">
                    <li>
                      Generan una mayor electrostática y deslizamiento, lo que permite una mejor incidencia en la
                      captura de insectos.
                    </li>
                  </ul>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn='btn03' :modal="nameModal03" :idModal="idModal03"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/atrayentes_especificos_02.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title text-left">Atrayentes Específicos</h5>
                  <p class="card-text" style="font-size: 13px; text-align: left;">
                  <ul style="text-align: left">
                    <li>Para Menthenol (Querciverol)</li>
                    <li>Alfa Copaeno</li>
                    <li>Atrayente para Dendroctonus</li>
                    <li>Atrayente para IPS</li>
                    <li>Etanol de baja y alta liberación</li>
                  </ul>
                  Somos distribuidores de la empresa SYNERGY <br>
                  SEMIOCHEMICALS CORP.
                  <br><br>
                  <a href="https://semiochemical.com/" target="_blank"><b>Ver catálago ></b></a>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn='btn02' :modal="nameModal02" :idModal="idModal02"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" style="display: flex; justify-content: center;">
          <div class="card mb-3" style="max-width: 620px; max-height: 320px; height: 320px; border-radius: 15px;">
            <div class="row no-gutters" style="height: 100%;">
              <div class="col-md-4" style="max-height: 320px;">
                <img src="@/assets/images/TrapCard03.png" class="card-img" alt="..." style="height: 100%;">
              </div>
              <div class="col-md-8" style="max-height: 320px;">
                <div class="card-body">
                  <h5 class="card-title text-left">
                    Trampas Tipo Delta
                  </h5>
                  <p class="card-text" style="font-size: 13px;">
                  <ul style="text-align: left">
                    <li>
                      Control y captura masiva de polillas de lepidópteros, plaga más frecuente en cultivos
                      protegidos y al aire libre, por medio de feromonas de confusión sexual.
                    </li>
                  </ul>
                  </p>
                  <div style="position: absolute; bottom: 15px; width: 100%;">
                    <ModalForm :textBtn="btn01" :modal="nameModal01" :idModal="idModal01"></ModalForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="movilCards">
        <div class="row">

          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; height: 400px; margin-bottom: 20px;">
              <img src="@/assets/images/TrapCard01M.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">
                  Trampas Multiembudos
                </h5>
                <p class="card-text" style="font-size: 13px;">
                <ul style="text-align: left">
                  <li>
                    Generan una mayor electrostática y deslizamiento, lo que permite una mejor incidencia en la
                    captura de insectos.
                  </li>
                </ul>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0">
                  <ModalForm :textBtn='btn03' :modal="nameModal03+'M'" :idModal="idModal03+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; height: 530px; margin-bottom: 20px;">
              <img src="@/assets/images/movil-02.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">
                  Atrayentes Específicos
                </h5>
                <p class="card-text" style="font-size: 13px; text-align: left;">
                <ul style="text-align: left">
                  <li>Para Menthenol (Querciverol)</li>
                  <li>Alfa Copaeno</li>
                  <li>Atrayente para Dendroctonus</li>
                  <li>Atrayente para IPS</li>
                  <li>Etanol de baja y alta liberación</li>
                </ul>
                Somos distribuidores de la empresa SYNERGY <br>
                SEMIOCHEMICALS CORP.
                <br><br>
                <a href="https://semiochemical.com/" target="_blank"><b>Ver catálago ></b></a>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0">
                  <ModalForm :textBtn='btn02' :modal="nameModal02+'M'" :idModal="idModal02+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem;border-radius: 15px; height: 440px; margin-bottom: 20px;">
              <img src="@/assets/images/TrapCard03M.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title text-left">
                  Trampas Tipo Delta
                </h5>
                <p class="card-text" style="font-size: 13px;">
                <ul style="text-align: left">
                  <li>
                    Control y captura masiva de polillas de lepidópteros, plaga más frecuente en cultivos
                    protegidos y al aire libre, por medio de feromonas de confusión sexual.
                  </li>
                </ul>
                </p>
                <div style="position: absolute; bottom: 15px; width: 100%; left: 0">
                  <ModalForm :textBtn="btn01" :modal="nameModal01+'M'" :idModal="idModal01+'M'"></ModalForm>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import ImagenPrincipal from "@/components/Inicio/ImagenPrincipal";
  import ImagenVerde from "@/components/Inicio/ImagenVerde";
  import ModalForm from "@/components/ModalForm";
  export default {
    name: "Trampas",
    components: {ModalForm, ImagenPrincipal, ImagenVerde},
    data(){
      return{
        imagenInicio: 'InjecthorBanner04.png',
        tituloInicio: 'Trampas y Atrayentes',
        parrafoInicio: 'Tratamiento de plagas y enfermedades agricolas, forestales y urbanas.',
        parrafoVerde: 'Injecthor de México ® somos una empresa conformada por expertos nacionales e internacionales\n' +
                      'en plagas y enfermedades de los árboles.',
        btn01: 'Solicitar Trampa',
        btn02: 'Solicitar Atrayente',
        btn03: 'Solicitar Protector',
        nameModal01: 'modal01',
        nameModal02: 'modal02',
        nameModal03: 'modal03',
        idModal01: 'modal01',
        idModal02: 'modal02',
        idModal03: 'modal03'
      }
    }
  }
</script>

<style scoped>
.btn-success{
  background: #1E1E1F !important;
  color: white;
  border-radius: 30px;
  border: none;
  width: 200px;
  height: 50px;
}

.btn-success:hover{
  background: #A0AF84 !important;
}

.card.mb-3:hover {
  box-shadow: 0 0px 4px 1px black;
}

.Cards{
  display: flex;
}

.movilCards{
  display: none;
}

@media (max-width: 768px) {
  .Cards{
    display: none;
  }

  .movilCards{
    display: block;
  }
}
</style>