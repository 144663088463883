<template>
  <div>

    <b-button v-b-modal="nameModal" class="btn btn-success">{{ texto }}</b-button>

    <b-modal
        :id="idModalForm"
        ref="modal"
        title="Contáctanos"
        title-class="prueba"
    >
      <form ref="form" @submit="handleSubmit" @reset="handleReset">
        <b-form-group>
          <b-form-input
            id="name-input"
            name="form[nombre]"
            v-model="form.name"
            :state="nameState"
            required
            type="text"
            placeholder="Ingresa tu nombre completo"
            class="inputStyle"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input
              id="email-input"
              name="form[email]"
              v-model="form.email"
              :state="emailState"
              required
              type="email"
              placeholder="Escribe tu email"
              class="inputStyle"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
            <b-form-textarea
                id="descInput"
                name="form[description]"
                v-model="form.description"
                :state="descState"
                required
                no-resize
                placeholder="¿Cómo podemos ayudarte?"
                class="inputStyle"
            ></b-form-textarea>
        </b-form-group>

        <div style="display: flex; justify-content: center">
          <b-button class="btn btn-dark" type="submitr">Enviar</b-button>
        </div>
      </form>

    </b-modal>

    <div>
      <b-modal hide-header hide-footer ref="modalEnviado" id="modalEnviado">
        <p id="pContact" class="my-4" style="font-weight: bold">
          Hemos recibido tu mensaje, ¡muchas gracias! <br>
          Pronto nos pondrémos en contacto contigo.
        </p>
        <template style="text-align: center">

          <div style="text-align: center;">
            <b-button id="hide-btn" size="sm" v-on:click="handleReset" class="btn btn-info">
              De acuerdo
            </b-button>
          </div>

        </template>
      </b-modal>
    </div>

  </div>
</template>

<script>

import QueryString from "querystring";
import ModalContac from "@/components/ModalContac";

export default {
  name: "ModalForm",
  components: {ModalContac},
  props: ['textBtn', 'modal', 'idModal'],
  data() {
    return {
      form: {
        name: '',
        email: '',
        description: '',
      },
      nameState: null,
      emailState: null,
      descState: null,
      submittedNames: [],
      texto: this.textBtn,
      nameModal: this.modal,
      idModalForm: this.idModal,
      nameModalClose: this.modal
    }
  },
  methods: {
    /*checkNameValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    checkEmailValidity() {
      const validEmail = this.$refs.form.checkValidity()
      this.emailState = validEmail
      return validEmail
    },
    checkDescValidity() {
      const validDescription = this.$refs.form.checkValidity()
      this.descState = validDescription
      return validDescription
    },*/

    handleSubmit(evt) {
      evt.preventDefault();

      // Exit when the form isn't valid
      /*if (!this.checkNameValidity() && !this.checkEmailValidity() && !this.checkDescValidity()) {
        return;
      }*/

      this.$nextTick(() => {
        this.$axios
            .post(
                "https://injecthor.chocolatux.com/calls/enviar.php",
                QueryString.stringify(this.form)
            )
            .then(res => {
              this.sent = true;
            })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
        this.$bvModal.hide(this.idModalForm);
        this.$refs['modalEnviado'].show();
      })
    },
    handleReset(evt){
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.description = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      });

      this.$refs['modalEnviado'].hide();
    }
  }
}
</script>

<style scoped>

  .inputStyle{
    background: none;
    border: none;
    margin-bottom: 10px;
    border-bottom: 1px solid #A0AF84;
    border-radius: 0;
    overflow-y: hidden !important;
  }

  .btn-dark{
    background: #1E1E1F !important;
    border-radius: 30px !important;
  }

  .btn-info{
    background: #A0AF84 !important;
    border-radius: 30px !important;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 1px solid #A0AF84 !important;
  }

  .btn-info:hover{
    background: #218838 !important;
    border: none !important;
  }

  .btn-success{
    background: #1E1E1F !important;
    color: white;
    border-radius: 30px;
    border: none;
    width: 200px;
    height: 50px;
  }

  .btn-success:hover{
    background: #A0AF84 !important;
  }

  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #A0AF84;
    border-color: #A0AF84;
  }

  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #A0AF84;
    border-color: #A0AF84;
    box-shadow: 0 0 5px 2px black !important;
  }

  .modal-header{
    border: none !important;
    justify-content: center !important;
  }

  .modal-header .close {
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    right: 25px;
  }
</style>

<style>

  #modal03___BV_modal_content_, #modal02___BV_modal_content_, #modal01___BV_modal_content_, #modalEnviado___BV_modal_content_{
    border: 4px solid #A0AF84;
  }

  #modal03___BV_modal_header_, #modal02___BV_modal_header_, #modal01___BV_modal_header_{
    border: none;
    justify-content: center;
  }

  #modal03___BV_modal_header_ > .close, #modal02___BV_modal_header_ > .close, #modal01___BV_modal_header_ > .close{
    margin: 0 !important;
    padding: 0;
    right: 25px;
    position: absolute;
  }

  #modal03___BV_modal_footer_, #modal02___BV_modal_footer_, #modal01___BV_modal_footer_{
    display: none;
  }

  /**/

  #modalBio03___BV_modal_content_, #modalBio02___BV_modal_content_, #modalBio01___BV_modal_content_, #modalBio04___BV_modal_content_{
    border: 4px solid #A0AF84;
  }

  #modalBio03___BV_modal_header_, #modalBio02___BV_modal_header_, #modalBio01___BV_modal_header_, #modalBio04___BV_modal_header_{
    border: none;
    justify-content: center;
  }

  #modalBio03___BV_modal_header_ > .close, #modalBio02___BV_modal_header_ > .close, #modalBio01___BV_modal_header_ > .close, #modalBio04___BV_modal_header_ > .close{
    margin: 0 !important;
    padding: 0;
    right: 25px;
    position: absolute;
  }

  #modalBio03___BV_modal_footer_, #modalBio02___BV_modal_footer_, #modalBio01___BV_modal_footer_, #modalBio04___BV_modal_footer_{
    display: none;
  }

  /**/

  #modalBio03M___BV_modal_content_, #modalBio02M___BV_modal_content_, #modalBio01M___BV_modal_content_, #modalBio04M___BV_modal_content_{
    border: 4px solid #A0AF84;
  }

  #modalBio03M___BV_modal_header_, #modalBio02M___BV_modal_header_, #modalBio01M___BV_modal_header_, #modalBio04M___BV_modal_header_{
    border: none;
    justify-content: center;
  }

  #modalBio03M___BV_modal_header_ > .close, #modalBio02M___BV_modal_header_ > .close, #modalBio01M___BV_modal_header_ > .close, #modalBio04M___BV_modal_header_ > .close{
    margin: 0 !important;
    padding: 0;
    right: 25px;
    position: absolute;
  }

  #modalBio03M___BV_modal_footer_, #modalBio02M___BV_modal_footer_, #modalBio01M___BV_modal_footer_, #modalBio04M___BV_modal_footer_{
    display: none;
  }

  /**/

  #modal03M___BV_modal_content_, #modal02M___BV_modal_content_, #modal01M___BV_modal_content_{
    border: 4px solid #A0AF84;
  }

  #modal03M___BV_modal_header_, #modal02M___BV_modal_header_, #modal01M___BV_modal_header_{
    border: none;
    justify-content: center;
  }

  #modal03M___BV_modal_header_ > .close, #modal02M___BV_modal_header_ > .close, #modal01M___BV_modal_header_ > .close{
    margin: 0 !important;
    padding: 0;
    right: 25px;
    position: absolute;
  }

  #modal03M___BV_modal_footer_, #modal02M___BV_modal_footer_, #modal01M___BV_modal_footer_{
    display: none;
  }

</style>