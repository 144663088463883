<template>
  <div class="container-fluid margenLetras" style="padding-top: 30px; margin-bottom: 100px;background: #F5F5F5">
    <div class="margenLetras" style="margin-left: 30px; margin-bottom: 30px">
      <h1 style="border-left: 10px solid #A0AF84; text-align: left; padding-left: 20px;">
        Productos y Servicios
      </h1>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="card" style="width: 18rem;">
            <div class="card-header">
              <p class="tituloCard">Sistema Injecthor Árboles</p>
            </div>
            <img src="../../assets/images/Card01.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">
                Sistema de inyección para tratamientos <br>
                preventivos y curativos en árboles
              </p>
              <div class="alineacion">
                <a href="/injecthorArboles" class="btn btn-dark">Mas Información...</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="card" style="width: 18rem;">
            <div class="card-header">
              <p class="tituloCard">Biotecnología Agroindustrial</p>
            </div>
            <img src="../../assets/images/Card02.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">
                Insumos para agricultura, productos <br>
                orgánicos biológicos y ecológicos
              </p>
              <div class="alineacion">
                <a href="/biotecnologia" class="btn btn-dark">Mas Información...</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="card" style="width: 18rem;">
            <div class="card-header">
              <p class="tituloCard">Trampas y Atrayentes</p>
            </div>
            <img src="../../assets/images/Card03.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">
                Tratamiento de plagas y enfermedades <br>
                agricolas, forestales y urbanas
              </p>
              <div class="alineacion">
                <a href="/trampas" class="btn btn-dark">Mas Información...</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "Cards"
  }
</script>

<style scoped>
  .card{
    border-radius: 15px;
  }

  .card-img-top{
    border-radius: 0 !important;
  }

  .card-header{
    background: white;
    border-radius: 15px 15px 0px 0px !important;
  }

  .col-sm-12{
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
  }

  .btn-dark{
    background: #1E1E1F;
    color: white;
    border-radius: 30px;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alineacion{
    display: flex;
    justify-content: center;
  }

  p{
    font-size: 14px;
    font-family: arial;
    margin-bottom: 15px;
  }

  .tituloCard{
    margin: 0;
    font-size: 18px;
    font-family: Futura;
  }

  .card:hover {
    box-shadow: 0 0px 4px 1px black;
  }

  a:hover{
    cursor: pointer;
  }

  @media(max-width: 768px){

    .margenLetras{
      margin-left: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

  }
</style>