<template>
  <b-carousel
      id="carousel-1"
      :interval="4000"
      controls
      background="white"
      min-height="520px"
      style="text-shadow: 1px 1px 2px #333; ext-align: center; justify-content: center; display: flex;"
      img-width="100%;"
      ref="carouselPrueba"
      :value="cargarIndice"
  >

    <b-carousel-slide v-for="(data, key) in megaArreglo" :id="'slide-'+key" :img-src="require('@/assets/images/Galeria/'+data.src)" class="tamanioGaleriaIMG">
      <div style="background: white; text-align: left;">
        <p style="font-family: Futura; font-size: 16px; color: black; margin-top: 5px; margin-bottom: 5px">{{data.titulo}}</p>
        <p style="font-family: Arial; font-style: italic; font-size: 14px; margin-bottom: 10px">{{data.mensaje}}</p>
      </div>
    </b-carousel-slide>

  </b-carousel>
</template>

<script>

  import { mapState } from 'vuex';

  export default {
    name: "GaleriaDinamica",
    data(){
      return{
        indice: 0
      }
    },
    props: ['indexImg'],
    computed: {
      ...mapState(['megaArreglo']),
      cargarIndice(){
        return this.indexImg;
      }
    }
  }
</script>

<style scoped>

  p{
    margin: 0;
    color: black;
  }

  .tamanioGaleriaIMG{
    min-height: 500px;
  }

  @media (max-width: 768px){
    .tamanioGaleriaIMG{
      min-height: inherit;
    }
  }
</style>

<style>

.carousel-caption{
  text-align: left !important;
  position: initial !important;
  background: white !important;
  text-shadow: none;
}

#carousel-1 > .carousel-control-prev {
  top: unset !important;
  position: absolute;
  left: -60px;
  bottom: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  border: 2px solid white;
  padding: 10px;
  border-radius: 5px;
  width: 45px;
}

#carousel-1 > .carousel-control-next {
  top: unset !important;
  position: absolute;
  right: -60px;
  bottom: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  border: 2px solid white;
  padding: 10px;
  border-radius: 5px;
  width: 45px;
}

#carousel-1 > .carousel-control-prev:hover {
  background: #A0AF84 !important;
  opacity: 0.9 !important;
}

#carousel-1 > .carousel-control-next:hover {
  background: #A0AF84 !important;
  opacity: 0.9 !important;
}

@media (max-width: 768px){
  #carousel-1 > .carousel-control-prev {
    top: unset !important;
    position: absolute;
    left: -60px;
    bottom: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    border: 2px solid white;
    padding: 10px;
    border-radius: 5px;
    width: 45px;
  }
  #carousel-1 > .carousel-control-next {
    top: unset !important;
    position: absolute;
    right: -60px;
    bottom: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    border: 2px solid white;
    padding: 10px;
    border-radius: 5px;
    width: 45px;
  }
}

</style>