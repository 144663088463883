<template>
  <div class="home">
    <Aviso/>
  </div>
</template>

<script>
// @ is an alias to /src
import Aviso from '@/components/Aviso.vue'

export default {
  name: 'AvisoPrivacidad',
  components: {
    Aviso
  }
}
</script>
