<template>
  <div>
    <div style="background: #1E1E1F; border-bottom: 4px solid #A0AF84;  bottom: 0; width: 100%; z-index: 1;">
      <div class="container-fluid justify-content-between row mx-0 py-1">
        <div class="font-weight-light col-md-3 col-12 text-center text-lg-left d-flex align-items-center flex-column flex-xl-row mb-1" style="justify-content: center;">
          <div class="d-flex justify-content-center" style="align-items: center;">
            <label for="" style="margin: 0; color: white; margin-right: 10px">Powered by:</label>
            <a href="https://chocolatux.com/main_es.html" target="_blank"><img src="@/assets/images/LogoChoco.svg" alt="" style="width: initial"></a>
          </div>
        </div>
        <div class="font-weight-light mx-auto col-md-6 col-12 my-3 d-flex flex-column justify-content-center text-center mb-1 parrafo" style="margin: 0;">
          <div class="font-weight-light" style="font-size: 15px; color: white">
            Derechos Reservados Injecthor de México 2020. Guadalajara, Jalisco, México.
            |
            <a class="text-white px-2" href="/#Contacto">Contacto</a> |
            <a class="text-white px-2" href="/aviso-privacidad">Aviso de Privacidad</a>
          </div>
        </div>
        <div class="font-weight-light col-md-3 col-12 mx-auto text-center d-flex justify-content-around justify-content-md-end align-items-center mb-1">
          <a target="_blank" href="https://www.facebook.com/Sistema-Injecthor-de-Mexico-567844933302582/" class="rounded-circle d-flex flex-column justify-content-center mx-2 text-white" style="width: 24px !important; height: 24px">
            <img src="../assets/images/Facebook.svg" alt="">
          </a>
          <a target="_blank" href="https://twitter.com/injecthor" class="rounded-circle d-flex flex-column justify-content-center mx-2 text-white" style="width: 24px !important; height: 24px">
            <img src="../assets/images/Twitter.svg" alt="">
          </a>
          <a target="_blank" href="https://www.instagram.com/injecthor" class="rounded-circle d-flex flex-column justify-content-center mx-2 text-white" style="width: 24px !important; height: 24px">
            <img src="../assets/images/Instagram.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
  img{
    width: 30px;
  }

  @media(max-width: 767px) {
    .tamanioMovil {
      height: 184px !important;
    }

    .parrafo{
      margin-bottom: 20px !important;
    }
  }
</style>