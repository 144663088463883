<template>
  <div>
    <ImagenPrincipal :dataImage="imagenInicio" :dataTitulo="tituloInicio" :dataParrafo="parrafoInicio"></ImagenPrincipal>
    <ImagenVerde :texto="parrafoVerde"></ImagenVerde>
    <Cards></Cards>
    <div style="position: relative; display: flex; justify-content: center; align-items: center;">

      <img src="@/assets/images/Figura02.png" alt="" style="width: 100%; min-height: 355px;position: absolute">

      <div class="container">
        <div class="row" style="align-items: center;">
          <div class="col-12">
            <div class="letrasVerdes" style="text-align: center; color: white;">
              <p>
                Nuestros productos están a la vanguardia tecnológica y con estricto apego a las normas ambientales y de calidad internacionales,
                y se especializan en dos áreas: <b>Agrícola/Forestal y Urbana.</b>
              </p>
              <p>
                Realizamos trabajos en sector publico y privado con eficacia demostrada a través de estudios de efectividad biológica
                de nuestros tratamientos integrales.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Carousel></Carousel>
    <Contacto id="Contacto"></Contacto>
  </div>
</template>

<script>
  import Cards from "@/components/Inicio/Cards";
  import Carousel from "@/components/Inicio/Carousel";
  import Contacto from "@/components/Inicio/Contacto";
  import Footer from "@/components/Footer";
  import ImagenPrincipal from "@/components/Inicio/ImagenPrincipal";
  import ImagenVerde from "@/components/Inicio/ImagenVerde";
  export default {
    name: "Inicio",
    components: {ImagenVerde, ImagenPrincipal, Footer, Contacto, Carousel, Cards},
    data(){
      return{
        imagenInicio: 'InjecthorBanner01.png',
        tituloInicio: 'Injecthor de México ®',
        parrafoInicio: 'Tratamientos integrales para la salud de los árboles ornamentales, forestales y frutales',
        parrafoVerde: 'Injecthor de México ® somos una empresa conformada por expertos nacionales e internacionales\n' +
                      'en plagas y enfermedades de los árboles.'
      }
    }
  }
</script>

<style scoped>

  .letrasVerdes{
    font-size: 20px;
  }

  @media(max-width: 768px){
    .movilStyle{
      font-size: 12px !important;
      padding-top: 17% !important;
    }
    .letrasVerdes{
      font-size: 12px !important;
    }
  }

  @media(max-width: 325px){
    .movilStyle{
      font-size: 12px !important;
      padding-top: 17% !important;
    }
  }

  .inicio{
    border-right: 2px solid #A0AF84;
    border-left: 2px solid #A0AF84;
    padding-right: 5px;
    padding-left: 5px;
  }

</style>