<template>
  <div style="background: #F5F5F5; padding-top: 35px">
    <div class="contactoMovil" style="margin-left: 30px; padding-bottom: 150px">
      <h1 style="border-left: 10px solid #A0AF84; text-align: left; padding-left: 20px; margin-bottom: 35px">
        Contáctanos
        <p class="text-left" style="font-size: initial; font-family: Arial">Dejános tus preguntas y/o comentarios, y te reponderemos lo más pronto posible </p>
      </h1>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="card areaForm" style="width: 430px; height: 330px">
              <div class="card-body">
                <p class="card-text">
                  <FormContacto></FormContacto>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div style="margin-bottom: 10px; display: flex; align-items: center">
              <img src="@/assets/images/FlorIcono.png" alt="">
              <label for="" style="font-weight: bold; font-size: 21px; margin: 0">Oficina Central</label>
            </div>
            <div style="margin-left: 20px; margin-bottom: 30px">
              <div style="display: flex; align-items: center">
                <img src="@/assets/images/Location.png" alt="">
                <p>Privada del Camichin 1974, Col. Villas de la loma. Zapopan, Jalisco.</p>
              </div>
              <div style="display: flex; align-items: center">
                <img src="@/assets/images/Phone.png" alt="">
                <p><a href="tel:+3313802333">331 380-2333</a> / <a href="tel:+3332015883">333 201-5883</a> </p>
              </div>
              <div style="display: flex; align-items: center">
                <img src="@/assets/images/Mail.png" alt="">
                <p>
                  <a href="mailto:injecthormexico@gmail.com" style="color: #1A3992; text-decoration: underline">injecthormexico@gmail.com</a> |
                  <a href="mailto:info@injecthormexico.com.mx" style="color: #1A3992; text-decoration: underline">info@injecthormexico.com.mx</a>
                </p>
              </div>
            </div>
            <div style="margin-bottom: 10px; display: flex; align-items: center">
              <img src="@/assets/images/FlorIcono.png" alt="">
              <label style="font-weight: bold; font-size: 21px; margin: 0">Oficina en CDMX</label>
            </div>
            <div style="margin-left: 20px">
              <div style="display: flex; align-items: center">
                <img src="@/assets/images/Location.png" alt="">
                <p>Salvatierra 33, Col. San Miguel Chapultepec. Ciudad de México. C.P.11850</p>
              </div>
              <p style="margin-left: 25px;">Contamos con representantes en la mayor parte de la República Mexicana.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ModalContac from "@/components/ModalContac";
import FormContacto from "@/components/FormContacto";
export default {
name: "Contacto",
  components: {FormContacto, ModalContac}
}
</script>

<style scoped>
  .btn-dark{
    border-radius: 30px;
    background: #1b1e21;
    color: white;
    width: 200px;
    height: 50px;
  }

  p{
    margin: 0;
    margin-left: 10px;
    font-size: 18px;
    font-family: Arial;
    text-align: left;
  }

  @media (max-width: 768px){
    .areaForm{
      width: auto !important;
      margin-bottom: 20px;
    }
    .contactoMovil{
      margin: 0 !important;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

</style>