<template>
  <div>
    <div style="position: relative; background: #F5F5F5">
      <img class="verdeDesk" src="@/assets/images/Figura01.png" alt="" style="width: 100%;min-height: 150px;">
      <div class="container-fluid" style="padding: 0;">
        <img class="verdeMovil" src="@/assets/images/verdeTopMovil.png" alt="" style="width: 100%;min-height: 150px;">
        <div style="display: flex; justify-content: center">
          <p class="container" style="position: absolute; color: white; top: 35px; font-size: 28px; text-align: center; margin-top: 10px">
            Galería de Clientes
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: 20px; margin-bottom: 100px;">
        <div class="row" style="justify-content: space-between; margin-bottom: 20px">

          <div v-for="(data, key) in megaArreglo" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 text-left">

            <ImagenesGaleria :titulo="data.titulo" :mensaje="data.mensaje" :src="data.src" :indice="key"></ImagenesGaleria>

          </div>

        </div>
    </div>
  </div>
</template>

<script>

import ImagenVerde from "@/components/Inicio/ImagenVerde";
import GaleriaDinamica from "@/components/GaleriaDinamica";
import ImagenesGaleria from "@/components/ImagenesGaleria";
import { mapState } from 'vuex';

export default {
  name: "Galeria",
  components: {GaleriaDinamica, ImagenVerde, ImagenesGaleria},
  data(){
    return {
    }
  },
  methods: {
    regresarImagen(){
      return this.$refs.carouselPrueba.slice(2)
    },
    prev() {
      return this.$refs.carouselPrueba.prev(2);
    }
  },
  computed: {
    ...mapState(['megaArreglo'])
  }
}

</script>

<style scoped>

.galeria{
  border-right: 2px solid #A0AF84;
  border-left: 2px solid #A0AF84;
  padding-right: 5px;
  padding-left: 5px;
}

p{
  margin: 0;
}

.verdeDesk{
  display: block;
}

.verdeMovil{
  display: none;
}

@media (max-width: 768px){

  .verdeDesk{
    display: none;
  }

  .verdeMovil{
    display: block;
  }

}
</style>

<style>

.close{
  color: white !important;
  opacity: 1 !important;
  position: absolute;
  right: -35px;
  top: 10px;
  font-size: 40px !important;
  font-weight: unset !important;
}

.cuerpo{
  width: 100%;
  /*min-width: 500px;*/
  background: white;
  padding: 11px !important;
  border-radius: 10px;
  /*min-height: 520px;*/
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.contenido{
  background: none !important;
  border: none !important;
  align-items: center;
}

#carousel-1___BV_inner_{
  width: 100% !important;
  /*min-width: 475px;*/
}

#silentbox-overlay__embed img, #silentbox-overlay__embed iframe{
  margin: 0 !important;
  position: inherit !important;
  background: white !important;
  padding: 20px !important;
  padding-bottom: 50px !important;
}

#silentbox-overlay__container{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
